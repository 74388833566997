@import url(./lib/components/form/form.css);
@import url(./lib/components/narrative/default/itinerary.css);
@import url(./lib/components/viewers/viewers.css);

button.header,
button.step,
.clear-button-formatting {
  background: inherit;
  color: inherit;
  border: 0;
  text-align: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

button.header,
button.step {
  width: 100%;
}
