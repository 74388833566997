@import "~normalize.css";

// IE 11 tweaks

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
button {
  background-color: rgba(0, 0, 0, 0);
}

@import "~@blueprintjs/core/lib/scss/variables";
$pt-intent-primary: rgb(48, 106, 181);
@import "~@blueprintjs/core/lib/css/blueprint";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime";
@import "~@blueprintjs/select/lib/css/blueprint-select";
@import "~leaflet/dist/leaflet.css";
@import "lib/index";

div.header {
  background-color: $pt-intent-primary;
  padding: 0 10px;
  height: 75px;
}

.map-container,
.map-container > .map {
  height: 100%;
}

.mode-icon {
  display: inline-block;
  height: 18px;
  width: 18px;
}

.svg-inline--fa {
  margin: 0px 4px;
}

form div.bp3-tabs {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.locations .bp3-form-content,
.locations .bp3-form-content .bp3-popover-target {
  width: 100%;
}

.itineraries .bp3-button-text {
  width: 100%;
}

.summary .bp3-breadcrumb {
  white-space: nowrap;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-top.leaflet-left,
.leaflet-bottom.leaflet-left {
  width: 60%;
}

.leaflet-top.leaflet-left .leaflet-control > div:not(:empty),
.leaflet-bottom.leaflet-left .leaflet-control > div:not(:empty) {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  max-width: 360px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .leaflet-top.leaflet-left .leaflet-control,
  .leaflet-bottom.leaflet-left .leaflet-control {
    width: 100%;
    min-width: 240px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .leaflet-top.leaflet-left .leaflet-control,
  .leaflet-bottom.leaflet-left .leaflet-control {
    width: 95%;
    min-width: 160px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .leaflet-top.leaflet-left .leaflet-control,
  .leaflet-bottom.leaflet-left .leaflet-control {
    width: 70%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .leaflet-top.leaflet-left .leaflet-control,
  .leaflet-bottom.leaflet-left .leaflet-control {
    width: 60%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .leaflet-top.leaflet-left .leaflet-control,
  .leaflet-bottom.leaflet-left .leaflet-control {
    width: 50%;
  }
}

@media screen and (max-height: 500px) {
  .leaflet-control .results {
    max-height: 200px;
    overflow-y: auto;
  }
}

@media screen and (max-height: 1500px) {
  .leaflet-control .results {
    max-height: 400px;
    overflow-y: auto;
  }
}

.bp3-portal {
  z-index: 9001;
}
