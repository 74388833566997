form .locations,
form .date-time-modal {
  margin: 10px 0px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

form hr {
  width: 90%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(#f0f0f0, #8c8b8b, #f0f0f0);
}

/* Date/Time Preview */

.date-time-preview {
  position: relative;
}

.date-time-preview.compressed .summary {
  text-align: center;
}

.date-time-preview.wide .button-container {
  float: right;
}

.date-time-preview.wide {
  height: 64px;
  color: #000;
}

.date-time-preview.compressed {
  text-align: center;
}

.date-time-preview .details {
  font-size: 14px;
  line-height: 24px;
}

/* Settings Preview */

.settings-preview {
  position: relative;
}

.settings-preview .selected-modes {
  font-size: 14px;
}

.settings-preview.compressed .selected-modes {
  text-align: center;
}

.settings-preview .selected-modes .some-selected .mode-icon {
  display: inline-block;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.settings-preview.compressed .selected-modes .some-selected .mode-icon {
  fill: black;
}

.settings-preview.wide .selected-modes .some-selected .mode-icon {
  fill: white;
}

.settings-preview.wide {
  height: 64px;
  color: #000;
}

.settings-preview.compressed {
  height: 95px;
  text-align: center;
  padding: 0px 15px;
}

.settings-preview .selected-modes .some-selected-label {
  margin-bottom: 4px;
}

.settings-preview .selected-modes .all-selected {
  height: 48px;
  padding-top: 2px;
}

.settings-preview.wide .selected-modes .all-selected {
  padding-top: 14px;
}

.settings-preview.wide .button-container {
  float: right;
}

/* Settings Selector Panel */

.settings-selector-panel {
  padding: 10px;
}

.settings-selector-panel .button-row {
  margin-bottom: 20px;
}

.settings-selector-panel .button-row .selected {
  background-color: #ccc;
}

/* Modes Selection Panel */

.modes-panel .mode-group-row {
  margin-bottom: 10px;
}

.modes-panel .mode-group-row .group-header {
  margin-bottom: 10px;
}

.modes-panel .mode-group-row .group-name {
  font-weight: 500;
  font-size: 16px;
}

.modes-panel .mode-group-row .group-select {
  float: right;
  text-align: right;
  font-size: 12px;
  padding-top: 5px;
}

.modes-panel .mode-group-row .mode-button-container {
  display: inline-block;
  width: 64px;
  height: 72px;
  margin-right: 10px;
  vertical-align: top;
  position: relative;
}

.modes-panel .mode-group-row .mode-button {
  border: 1px solid gray;
  padding: 3px;
  /* border-radius: 3px; */
  width: 64px;
  height: 48px;
}

.modes-panel .mode-group-row .mode-button:hover {
  background-color: #f8f8f8;
}

.modes-panel .mode-group-row .mode-check {
  position: absolute;
  top: -6px;
  right: -2px;
  width: 12px;
  height: 12px;
  font-size: 16px;
}

.modes-panel .mode-group-row .mode-icon {
  width: 56px;
  height: 30px;
}

.modes-panel .mode-group-row .mode-label {
  font-size: 10px;
  text-align: center;
  line-height: 12px;
  padding-top: 4px;
}

.modes-panel .mode-group-row button {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}
