/* ITINERARY OPTION */

.itinerary button:focus {
  outline: none;
}

.itinerary {
  /* margin-bottom: 16px; */
  padding: 10px;
}

.otp .option.active .default-itin {
  background-color: #eaf3f2;
}

.itinerary > .header {
  font-size: 16px;
  cursor: pointer;
}

.itinerary > .header > .title {
  font-weight: bold;
}

.itinerary > .header > .summary {
  text-align: center;
  margin: 8px 0px;
}

/* ITINERARY LEG */

.itinerary .leg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #bbb;
}

@supports (background-color: var(--hover-color)) {
  .itinerary .leg:hover {
    background-color: var(--hover-color);
  }
}

.itinerary .leg.active {
  background-color: #fff;
}

.itinerary .leg > .header {
  cursor: pointer;
  padding: 0;
}

.itinerary .transit-leg .header .mode-icon-container {
  float: left;
  width: 28px;
  height: 28px;
  margin-top: 4px;
}

.itinerary .transit-leg .header .route-name {
  margin-left: 36px;
}

.itinerary .route-short-name {
  vertical-align: middle;
  background-color: #888;
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  padding: 1px 5px 2px 5px;
  /* border-radius: 8px; */
}

.itinerary .transit-leg .route-short-name {
  margin-right: 5px;
}

.itinerary .transit-leg .route-long-name {
  vertical-align: middle;
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 0.25em;
}

.itinerary .transit-leg-body {
  display: table;
  margin-top: 10px;
  width: 100%;
}

.itinerary .transit-leg-body .from-row,
.itinerary .transit-leg-body .trip-details-row,
.itinerary .transit-leg-body .to-row {
  display: table-row;
}

.itinerary .transit-leg-body .time-cell {
  display: table-cell;
  width: 45px;
  font-size: 10px;
  vertical-align: top;
  text-align: right;
  padding-right: 5px;
  padding-top: 2px;
}

.itinerary .transit-leg-body .trip-line-cell {
  position: relative;
  display: table-cell;
  width: 12px;
}

.itinerary .transit-leg-body .trip-line-top {
  position: absolute;
  left: 4px;
  top: 10px;
  bottom: 0;
  width: 4px;
  background-color: #888;
}

.itinerary .transit-leg-body .trip-line-middle {
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #888;
}

.itinerary .transit-leg-body .trip-line-bottom {
  position: absolute;
  left: 4px;
  top: 0px;
  height: 10px;
  width: 4px;
  background-color: #888;
}

.itinerary .transit-leg-body .trip-line-stop {
  position: absolute;
  top: 8px;
  left: -15px;
  height: 4;
  width: 8;
  background-color: #888;
}

.itinerary .transit-leg-body .stop-bubble {
  position: absolute;
  top: 4px;
  width: 12px;
  height: 12px;
  border: 2px solid black;
  background-color: #fff;
  border-radius: 6px;
}

.itinerary .transit-leg-body .stop-name-cell {
  display: table-cell;
  padding-left: 8px;
  padding-top: 2px;
  line-height: 16px;
}

.itinerary .transit-leg-body .trip-details-cell {
  display: table-cell;
  padding: 12px 0px 12px 8px;
}

.itinerary .leg .stop-count {
  cursor: pointer;
}

.itinerary .leg .stop-list {
  cursor: pointer;
  list-style: none;
}

.itinerary .leg .alert-item {
  padding-left: 22px;
}

.itinerary .leg .stop-list .stop-item {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
}

.itinerary .leg .info-item {
  font-size: 11px;
  font-style: italic;
  line-height: 13px;
  margin-top: 6px;
}

/* ITINERARY STEP */

.itinerary .step {
  cursor: pointer;
  background-color: #eee;
  margin-bottom: 4px;
  padding: 4px;
  font-size: x-small;
}

.itinerary .step.active {
  background-color: #ccc;
  /*border: 1px dotted;*/
}

.itinerary .step .step-distance {
  float: right;
}

/* Itinerary Utils */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
