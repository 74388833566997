/* shared stop/trip viewer styles */

.stop-viewer-header,
.trip-viewer-header,
.route-viewer-header {
  padding: 10px;
}

.leaflet-left.leaflet-top .leaflet-control > div {
  max-height: 66vh;
  overflow: auto;
}

.stop-viewer .back-button-container,
.trip-viewer .back-button-container,
.route-viewer .back-button-container {
  float: left;
  margin-right: 10px;
}

.stop-viewer .header-text,
.trip-viewer .header-text,
.route-viewer .header-text {
  font-weight: 700;
  font-size: 24px;
}

/* stop viewer styles */

.stop-viewer .route-row {
  border: 1px solid black;
  margin-top: 10px;
  border-radius: 5px;
}

.stop-viewer .route-row .header {
  display: table;
  width: 100%;
}
.stop-viewer .route-row .header .route-name {
  display: table-cell;
  padding: 8px;
}

.stop-viewer .route-row .header .next-trip-preview {
  display: table-cell;
  width: 85px;
  border-left: 1px solid gray;
  padding: 9px 6px;
  font-size: 14px;
}

.stop-viewer .route-row .header .expansion-button-container {
  display: table-cell;
  width: 36px;
  border-left: 1px solid gray;
  padding: 0px;
  cursor: pointer;
}

.stop-viewer .route-row .header .expansion-button {
  background: none;
  border: none;
  padding: 8px 8px 4px 8px;
}

.stop-viewer .route-row .header .expansion-button:hover {
  color: #ddd;
}

.stop-viewer .route-row .header .expansion-button:focus {
  outline: 0px;
}

.stop-viewer .route-row .trip-table {
  border-top: 1px solid gray;
  padding: 8px;
}

.stop-viewer .route-row .trip-table-column-header {
  float: right;
  width: 80px;
  font-weight: 500;
  text-align: center;
}

.stop-viewer .route-row .trip-table .trip-row .status-label {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 2px;
  font-size: 11px;
  color: white;
  padding: 2px 0px 0px 0px;
}

/* trip viewer styles */

.trip-viewer .stop-time {
  float: left;
  width: 55px;
  font-size: 11px;
  padding-top: 7px;
  padding-right: 5px;
  text-align: right;
}

.trip-viewer .strip-map-container {
  position: relative;
  float: left;
  width: 20px;
  height: 30px;
}

.trip-viewer .strip-map-highlight {
  position: absolute;
  background-color: cyan;
  width: 20px;
  height: 30px;
}

.trip-viewer .strip-map-highlight-first {
  position: absolute;
  background-color: cyan;
  top: 2px;
  width: 20px;
  height: 28px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.trip-viewer .strip-map-highlight-last {
  position: absolute;
  background-color: cyan;
  top: 0px;
  width: 20px;
  height: 28px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.trip-viewer .strip-map-line {
  position: absolute;
  left: 10px;
  width: 4px;
  background-color: #000;
  height: 30px;
}

.trip-viewer .strip-map-line-first {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 4px;
  background-color: #000;
  height: 15px;
}

.trip-viewer .strip-map-line-last {
  position: absolute;
  top: 0;
  left: 10px;
  width: 4px;
  background-color: #000;
  height: 15px;
}

.trip-viewer .strip-map-icon {
  position: absolute;
  left: 2px;
  top: 8px;
  font-size: 12.5px;
  color: #000;
}

.trip-viewer .stop-name {
  font-size: 14px;
  margin-left: 80px;
  margin-right: 55px;
  padding-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
